<template>
  <page-header-wrapper>
    <a-card>
      <div class="app-container">
        <a-radio-group v-model="pagetype" style="margin-bottom: 16px" @change="changeType">
          <a-radio-button :value="1">
            分销设置
          </a-radio-button>
          <a-radio-button :value="2">
            手动申请审核
          </a-radio-button>
        </a-radio-group>
        <a-form v-show="pagetype == 1" ref="form" :form="form" @submit="submit" :rules="rules" :label-col="{ span: 4 }"
          :wrapper-col="{ span: 8 }">
          <a-form-item label="分销总开关" class="margin-bottom-10">
            <a-radio-group v-model="form.isOpen" button-style="solid">
              <a-radio-button v-for="(d, index) in openOption" :key="index" :value="parseInt(d.value)">
                {{ d.label }}
              </a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="分销级别" class="margin-bottom-10">
            <a-select v-model="form.level" style="width: 120px">
              <a-select-option :value="1">
                一级
              </a-select-option>
              <a-select-option :value="2">
                二级
              </a-select-option>
              <a-select-option :value="3">
                三级
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="分销商升级方式" class="margin-bottom-10">
            <a-radio-group v-model="form.upgradeType" button-style="solid">
              <a-radio-button v-for="(d, index) in upgradeTypeOption" :key="index" :value="parseInt(d.value)">{{ d.label
              }}</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="分销商级别" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" class="margin-bottom-10">
            <div class="margin-bottom-10">
              <a-button type="primary" @click='addLevel'>
                添加级别
              </a-button>
            </div>
            <a-table :data-source="levelList" :columns="levelColumns" rowKey="id" :pagination="false" bordered>
              <div slot="standardType" slot-scope="text, record">{{ text | standarName }}</div>
              <div slot="conditionName" slot-scope="text, record">
                <div v-for="(item, index) in text">{{ item }}</div>
                <div v-if="!text.length">--</div>
              </div>
              <div slot="action" slot-scope="text, record">
                <a href="javascript:void(0)" @click='editLevel(record)' class="margin-right-sm">编辑</a>
                <a-popconfirm title="确定要删除此等级吗？" ok-text="确定" cancel-text="取消" @confirm="delLevel(record)">
                  <a href="javascript:void(0)" v-if="record.index != 1" style="color:red;">删除</a>
                </a-popconfirm>
              </div>
            </a-table>
          </a-form-item>
          <a-form-item prop="agreementTitle" label="分销商协议标题" class="margin-bottom-10">
            <a-input v-model="form.agreementTitle" allow-clear />
          </a-form-item>
          <a-form-item prop="agreement" label="分销商协议内容" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
            <editor v-model="form.agreement" placeholder="请输入分销商协议内容" />
          </a-form-item>
          <a-row class="margin-top-xl">
            <a-col :span="4"></a-col>
            <a-col :span="14">
              <a-button size="large" type="primary" html-type="submit" style="width:200px">
                保存
              </a-button>
            </a-col>
          </a-row>
        </a-form>
        <div v-show="pagetype == 2">
          <appliList :levelList="levelList" v-if="levelList.length"></appliList>
        </div>
      </div>
    </a-card>
    <!--修改级别弹窗-->
    <setLevel ref='levelDrawer' @updateLevels="updateLevels"></setLevel>
  </page-header-wrapper>
</template>
<script>
import Editor from '@/components/Editor'
import setLevel from '@/views/agent/setLevel'
import * as distribution from '@/api/distribution'
import appliList from './components/applyList.vue'
export default {
  name: "DisSetting",
  components: {
    Editor,
    setLevel,
    appliList
  },
  data() {
    return {
      openOption: [{
        label: '开启',
        value: 1
      }, {
        label: '关闭',
        value: 0
      }],
      upgradeTypeOption: [{
        label: '逐级升级',
        value: 1
      }, {
        label: '跳级升级',
        value: 2
      }],
      queryParams: {
        name: ''
      },
      pagetype: 1,

      form: {
        isOpen: 1,
        upgradeType: 1,
        level: 2,
        agreement: '',
        agreementTitle: ''
      },
      rules: {
        agreementTitle: [
          { required: true, message: '请输入分销商协议标题', trigger: 'blur' },
        ],
        agreement: [
          { required: true, message: '请输入分销商协议内容', trigger: 'blur' },
        ],
      },
      levelList: [],
      levelColumns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index'
        }, {
          title: '级别名称',
          dataIndex: 'title',
          key: 'title'
        }, {
          title: '达标方式',
          dataIndex: 'standardType',
          key: 'standardType',
          scopedSlots: { customRender: 'standardType' }
        }, {
          title: '条件',
          dataIndex: 'conditionName',
          key: 'conditionName',
          scopedSlots: { customRender: 'conditionName' }
        }, {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          slots: { title: 'addbtn', customRender: 'name' },
          scopedSlots: { customRender: 'action' }
        }
      ]
    };
  },
  filters: {
    standarName(type) {
      let str = ''
      switch (type) {
        case 1: str = '满足任一条件';
          break;
        case 2: str = '满足全部条件';
          break;
        case 3: str = '不限制';
          break;
        case 4: str = '手动申请';
          break;
      }
      return str
    }
  },
  mounted() {
    this.getDistrbuteInfo()
  },
  methods: {
    changeType(e) {
      if (e.target.value === 2) {
        this.$refs.levelDrawer.getLevelList()
      }
    },
    /**
     * 添加级别成功后，更新等级列表数据
     */
    updateLevels() {
      this.getDistrbuteInfo()
    },
    getDistrbuteInfo() {
      distribution.getDistributeConfig().then(res => {
        if (res.success) {
          this.form = res.data
          this.levelList = res.data.levelConfigs
          this.levelList.map((item, index) => {
            item.index = index + 1
            item.conditionName = []
            if (item.levelConditions) {
              item.levelConditions.map((item1, index1) => {
                let obj = JSON.parse(item1.conditionValueJson)
                if (item1.conditionType == 1) {
                  let str1 = this.consumeType(obj.typeValue)
                  let str2 = this.effectiveStatus(obj.activeValue)
                  let name = str1 + obj.moneyValue + '元,' + str2
                  item.conditionName.push(name)
                } else if (item1.conditionType == 2) {
                  let name = this.giftPacksType(obj.typeValue)
                  item.conditionName.push(name)
                } else if (item1.conditionType == 3) {
                  let name = '购买商品' + obj.buyNumValue + '次'
                  item.conditionName.push(name)
                } else if (item1.conditionType == 4) {
                  let name = '团队业绩' + obj.teamSalesValue + '元'
                  item.conditionName.push(name)
                } else if (item1.conditionType == 5) {
                  let name = this.identityType(obj)
                  item.conditionName.push(name)
                } else if (item1.conditionType == 6) {
                  let name = this.invitePerson(obj, 6)
                  item.conditionName.push(...name)
                } else if (item1.conditionType == 7) {
                  let name = this.invitePerson(obj, 7)
                  item.conditionName.push(...name)
                }
              })
            }
            if (item.standardType == 4) {
              let name = this.initApply(item.applyConfig)
              item.conditionName.push(...name)
            }
          })
        }
      })
    },
    addLevel() {
      this.$refs.levelDrawer.onClearForm()
      this.$refs.levelDrawer.showDrawer()
      this.$refs.levelDrawer.getLevelList()
    },
    /**
     * 编辑等级
     */
    editLevel(info) {
      this.$refs.levelDrawer.getInfo({
        id: info.id
      })
      this.$refs.levelDrawer.showDrawer({ id: info.id })
    },
    /**
     * 删除等级
     */
    delLevel(info) {
      distribution.delLevelInfo({ id: info.id }).then(response => {
        if (response.success) {
          let delIndex = this.levelList.findIndex(item => { return info.id == item.id })
          this.levelList.splice(delIndex, 1)
          this.levelList.map((item, index) => { item.index = index + 1 })
          this.$message.success('删除成功')
        } else {
          this.$message.success('删除失败')
        }
      })
    },
    submit(e) {
      e.preventDefault()
      distribution.addDisbutionConfig(this.form).then(res => {
        if (res.success) {
          this.$message.success('添加成功')
        } else {
          this.$message.error(res.message)
        }
      }).catch(res => {
        this.$message.error(res.message)
      })
    },
    // 消费类型
    consumeType(type) {
      let str = ''
      switch (type) {
        case 1: str = '商城总消费';
          break;
        case 2: str = '一次性消费';
          break;
        default: str = ''
      }
      return str
    },
    // 生效状态
    effectiveStatus(type) {
      let str = ''
      switch (type) {
        case 1: str = '订单付款后计入';
          break;
        case 2: str = '订单确认收货后计入';
          break;
        default: str = ''
      }
      return str
    },
    giftPacksType(type) {
      let str = ''
      switch (type) {
        case 1: str = '购买任意商品成为分销商';
          break;
        case 2: str = '购买特定商品成为分销商';
          break;
        default: str = ''
      }
      return str
    },
    identityType(obj) {
      let str = ''
      switch (obj.configValue) {
        case 1: str = obj.moneyValue + '元直接购买';
          break;
        case 2: str = obj.moneyValue + '元购买并送产品';
          break;
        case 3: str = obj.moneyValue + '元购买，并赠送金额' + obj.freeMoney + '元';
          break;
        default: str = ''
      }
      return str
    },
    invitePerson(arr, index) {
      let str = ''
      index == 6 ? str = '直接' :
        index == 7 ? str = '团队' : ''
      let arr2 = []
      arr.forEach((item, index) => {
        this.levelList.forEach((item1, index1) => {
          if (item.levelValue == item1.level) {
            let str = '邀请等级为' + item1.title + '的分销商' + item.peopleValue + '人'
            arr2.push(str)
          }
        })
      })
      return arr2
    },
    initApply(applyConfig) {
      // console.log(applyConfig)
      let config = JSON.parse(applyConfig)
      let arr = []
      config.forEach((item, index) => {
        let str = ""
        if (item.fieldtype == "1") {
          str = "文本框"
        } else if (item.fieldtype == "2") {
          str = "选择框"
        } else if (item.fieldtype == "3") {
          str = "地区选择"
        } else if (item.fieldtype == "4") {
          str = "图片"
        }
        str = str + '：' + item.fieldname + '---' + item.initialcontent
        arr.push(str)
      })

      return arr
    }
  }
}
</script>
<style scoped lang="less">
.tip {
  line-height: 20px !important;
  font-size: 12px;
}
</style>
<!--
      this.levelList = [
        {
          id: 1,
          index: 1,
          levelName: '消费者',
          arrive_limit_desc: '不限制（无条件）',
          level_rules_desc: '--',
          commission: 0
        }, {
          id: 2,
          index: 2,
          levelName: '银卡店',
          arrive_limit_desc: '满足任一设置条件',
          level_rules_desc: '付款记入的一次性消费额为899.00元，团队业绩2697.00元',
          commission: 0
        }, {
          id: 3,
          index: 3,
          levelName: '金卡店',
          arrive_limit_desc: '满足任一设置条件',
          level_rules_desc: '付款记入的一次性消费额为899.00元，团队业绩2697.00元',
          commission: 0
        }
      ]*/
      /**
      this.levelList = [
        {
          "Level_ID": 2405,
          "Users_ID": "wjya8qmyhg",
          "Level_Name": "消费者",
          "Level_Description": "",
          "Level_Icon": "",
          "Level_BackImg": "",
          "Level_PeopleLimit": {
            "1": 0
          },
          "arrive_limit": "1",
          "level_rules": [],
          "manual_rules": [],
          "commi_rules": [],
          "arrive_limit_desc": "不限制（无条件）",
          "level_rules_desc": []
        },
        {
          "Level_ID": 2407,
          "Users_ID": "wjya8qmyhg",
          "Level_Name": "银卡店商",
          "Level_Description": "",
          "Level_Icon": "",
          "Level_BackImg": "",
          "Level_PeopleLimit": {
            "1": 0
          },
          "arrive_limit": "3",
          "level_rules": {
            "pay_money": {
              "type": "2",
              "money": "899.00",
              "arrive_status": "2"
            },
            "team_sales": "2697.00"
          },
          "manual_rules": [],
          "commi_rules": [],
          "arrive_limit_desc": "满足任一设置条件",
          "level_rules_desc": [
            "付款记入的一次性消费额为899.00元",
            "团队业绩2697.00元"
          ]
        },
        {
          "Level_ID": 2421,
          "Users_ID": "wjya8qmyhg",
          "Level_Name": "金卡店商",
          "Level_Description": "",
          "Level_Icon": "",
          "Level_BackImg": "",
          "Level_PeopleLimit": {
            "1": 0
          },
          "arrive_limit": "3",
          "level_rules": {
            "pay_money": {
              "type": "2",
              "money": "3596.00",
              "arrive_status": "2"
            },
            "team_sales": "8091.00",
            "direct_sons": [
              {
                "level_id": 2407,
                "count": "4",
                "level_name": "银卡店商"
              }
            ]
          },
          "manual_rules": [],
          "commi_rules": [],
          "arrive_limit_desc": "满足任一设置条件",
          "level_rules_desc": [
            "付款记入的一次性消费额为3596.00元",
            "团队业绩8091.00元",
            "直接邀请等级为银卡店商（Level_ID=2407）的分销商4人"
          ]
        },
        {
          "Level_ID": 2422,
          "Users_ID": "wjya8qmyhg",
          "Level_Name": "铂金店商",
          "Level_Description": "",
          "Level_Icon": "",
          "Level_BackImg": "",
          "Level_PeopleLimit": {
            "1": 0
          },
          "arrive_limit": "3",
          "level_rules": {
            "pay_money": {
              "type": "2",
              "money": "8990.00",
              "arrive_status": "2"
            },
            "team_sales": "17980.00",
            "direct_sons": [
              {
                "level_id": 2421,
                "count": "4",
                "level_name": "金卡店商"
              }
            ]
          },
          "manual_rules": [],
          "commi_rules": [],
          "arrive_limit_desc": "满足任一设置条件",
          "level_rules_desc": [
            "付款记入的一次性消费额为8990.00元",
            "团队业绩17980.00元",
            "直接邀请等级为金卡店商（Level_ID=2421）的分销商4人"
          ]
        }
      ] -->